module controllers {
    export module payment {

        interface IPaymentListCtrlScope extends ng.IScope {


        }

        interface IPaymentListParams extends ng.ui.IStateParamsService {
            FilterList: interfaces.applicationcore.IKeyValue[];
            SearchList: interfaces.applicationcore.IKeyValue[];
        }

        export class paymentListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'paymentService',
                '$state',
                '$stateParams',
                '$transitions',
                'statusService',
                'classificationValueService',
                "supplierService",
                "customerService",
                "isInbound",
            ];

            paymentSearch: interfaces.payment.IPaymentSearch = {
                statusId: -1
            };

            IsLoading: boolean = false;
            ShowGrid: boolean = false;

            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.payment.IPaymentDisplay>;
            searchAccordian: boolean = false;
            myHook: any;
            myHook2: any;

            fromDateOpen: boolean = false;
            toDateOpen: boolean = false;
            filterDateOpen: boolean = false;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string;

            sortName: string;
            sortDirection: string;


            dropdownsObject = {
                PaymentStatusList: Array<interfaces.applicationcore.IDropdownModel>(),
            };

            IsInbound: boolean = false;
            ownerEntityId: number;

            statusList: interfaces.applicationcore.IDropdownModel[] = [];

            apiList: uiGrid.IGridApi;
            selected: uiGrid.IGridRow;

            items: string[];
            entity: string;
            currentEntity: interfaces.applicationcore.IEntity;

            selectedRowsArray: Array<interfaces.payment.IPaymentDisplay> = [];

            filterNames: string[] = ["EntityCode",
                "PaymentCode",
                "PaymentDate",
                "SupplierName",
                "CustomerName",
                "CurrencyCode",
                "ValueDate",
                "PaidDate",
                "IssuingBank",
                "ResponsibleUserName",
                "Status"];

            filterList: interfaces.applicationcore.IKeyValue[] = [];



            constructor(private $scope: IPaymentListCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private paymentService: interfaces.payment.IPaymentService,
                private $state: ng.ui.IStateService,
                private $stateParams: IPaymentListParams,
                private $transitions: ng.ui.core.ITransition,
                private statusService: interfaces.master.IStatusService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private supplierService: interfaces.master.ISupplierService,
                private customerService: interfaces.master.ICustomerService,
                private isInbound: boolean,
            ) {

                this.IsInbound = isInbound;

                if ($stateParams.Items) {
                    this.items = $stateParams.Items;
                    this.entity = $stateParams.Entity;
                }

                if ($stateParams.FilterList) {
                    this.paymentSearch = {};
                    this.filterList = $stateParams.FilterList;
                }

                var obj: any = {}
                if ($stateParams.SearchList) {
                    angular.forEach($stateParams.SearchList, (value, index) => {
                        obj[value.key] = value.value;
                    });
                    this.paymentSearch = obj;
                    this.paymentSearch.statusId=-1;
                }


                if (this.isInbound) {
                    this.ctrlName = 'Supplier Payment List Controller';
                }
                else {
                    this.ctrlName = 'Customer Payment List Controller';
                }

                this.loadControls();

                this.myHook = $transitions.onSuccess({
                    to: 'auth.CustomerPayments',
                    from: 'auth.CustomerPayments.**'
                }, () => {
                    this.loadPayments();
                });

                this.myHook2 = $transitions.onSuccess({
                    to: 'auth.SupplierPayments',
                    from: 'auth.SupplierPayments.**'
                }, () => {
                    this.loadPayments();
                });

                /* $scope.$on('$destroy', () => {
                     $transitions.onStart({}, this.myHook)
                 });*/
            }

            loadPayments() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'payment'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.paymentSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            items: this.items,
                            entityCode: this.entity,
                            pageNumber: this.paginationOptions.pageNumber,
                            IsInbound: this.IsInbound
                        }

                        return this.paymentService.getList().query(params, (data) => {
                            this.IsLoading = false;
                            this.gvwList.data = data;
                            this.selectedRowsArray = [];

                            if (data[0]) {
                                this.gvwList.totalItems = data[0].NumberRecords;
                            } else {
                                this.gvwList.totalItems = 0;
                            }
                            this.$timeout(() => {
                                if (this.apiList) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });

                        }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });

            }





            loadControls() {
                let controlPromises = [
                    this.getCurrentEntity(),
                    this.loadStatuses()
                ]

                this.$q.all(controlPromises).then((data) => {

                    this.ShowGrid = true;
                    this.DoSearch();
                });

            }

            createNewProduct() {
                this.$state.go("auth.MasterData.Product.Update", { proId: 0 });
            }

            search_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;

                this.DoSearch();
            }

            showAll_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;
                this.paymentSearch = {
                    statusId: -1
                }
                this.filterList = [];

                if (this.apiList !== undefined) {
                    this.apiList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            DoSearch() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.paymentSearch, (n, key) => {

                    if (n) {
                        if (n["Id"]) {

                            searchObject.filters.push({ Name: key, Value: n.Id });

                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    }
                });

                if (this.apiList) {
                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                if (this.currentEntity)
                    this.entity = this.currentEntity.Code;

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    items: this.items,
                    entityCode: this.entity,
                    pageNumber: this.paginationOptions.pageNumber,
                    IsInbound: this.IsInbound
                }

                this.paymentService.getList().query(params, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = data;
                    this.selectedRowsArray = [];

                    if (data[0]) {
                        this.gvwList.totalItems = data[0].NumberRecords;
                    } else {
                        this.gvwList.totalItems = 0;
                    }


                    this.$timeout(() => {
                        if (this.apiList) {
                            this.getFilters();
                            this.setFilters();
                        }
                    });


                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }


            calculatePaymentDueDate_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'payment'
                }, () => {
                    return this.calculatePaymentDueDate().then((result) => {
                        this.generalService.displayMessageHandler(result);
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });

            };

            calculatePaymentDueDate() {
                return this.paymentService.calculatePaymentDueDate().save({
                    IsInbound: this.IsInbound
                }).$promise;
            };

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.paymentSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiList) {

                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    items: this.items,
                    entityCode: this.entity,
                }

                this.paymentService.getPaymentsExcel(params, this.IsInbound).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            loadStatuses() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.statusService.getStatusDropdownList().query({
                    type: this.isInbound ? Enum.EnumStatusType.SupplierPayment : Enum.EnumStatusType.CustomerPayment
                }, (result) => {

                    this.statusList = result;
                    defered.resolve(result);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }



            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            loadCustomers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.customerService.getDropdownList(this.ownerEntityId, searchText).query({
                    }, () => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadSuppliers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.supplierService.getSupplierDropdown(this.ownerEntityId, searchText).query({
                    }, () => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }


            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiList.grid.getColumn(name).filters[0].term || this.apiList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.apiList.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            ListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ListShortChange(gridApi) {
                this.DoSearch();
            }

            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if (!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if (keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });

                this.DoSearch();
            }


            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiList = gridApi;

                this.apiList.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });
                this.apiList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiList.core.on.rowsRendered(this.$scope, () => {

                    for (let selectedRow of this.selectedRowsArray) {

                        for (var row of this.gvwList.data) {

                            if (selectedRow.Id === row.Id) {
                                this.apiList.selection.selectRow(row);
                            }
                        }
                    }
                });

                this.apiList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selected = selected;

                    if (!selected.isSelected) {

                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                this.selectedRowsArray.splice(this.selectedRowsArray.indexOf(selectedRow), 1);
                            }
                        }
                    }
                    else {
                        var Exists: number = 0;
                        for (let selectedRow of this.selectedRowsArray) {
                            if (selectedRow.Id === selected.entity.Id) {
                                Exists = 1;
                            }
                        }

                        if (Exists === 0) {
                            this.selectedRowsArray.push(selected.entity);
                        }
                    }
                });

                this.$timeout(() => {
                    if (this.selected)
                        this.apiList.selection.selectRow(this.selected.entity);
                });
            }

            editPayment(payId: number) {
                if (this.isInbound) {
                    this.$state.go("auth.SupplierPayments.Update", { payId: payId });
                } else {
                    this.$state.go("auth.CustomerPayments.Update", { payId: payId });
                }
            };

            createPayment() {
                if (this.isInbound) {
                    this.$state.go("auth.SupplierPayments.Create", { payId: 0 });
                } else {
                    this.$state.go("auth.CustomerPayments.Create", { payId: 0 });
                }
            }

            printDocuments() {
                if (this.apiList && this.apiList.selection.getSelectedRows().length > 0) {
                    var selected = this.apiList.selection.getSelectedRows()[0];

                    if (this.isInbound) {
                        this.$state.go("auth.SupplierPayments.Update.Documents", { payId: selected.Id });
                    } else {
                        this.$state.go("auth.CustomerPayments.Update.Documents", { payId: selected.Id });
                    }

                } else {
                    this.generalService.displayMessage("Please select a Payment", Enum.EnumMessageType.Warning);
                }
            }


            gvwList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) 
                
                    this.apiList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiList.selection.selectRow(newCol.row.entity);
                        });
                    });

                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div> 
                                <button type="button"  ng-click="grid.appScope.paymentListCtrl.editPayment(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 60,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "PaymentCode",
                    displayName: "Payment Number",
                    field: "PaymentCode",
                    width: 130,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "PaymentDate",
                    displayName: "Payment Date",
                    field: "PaymentDate",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="PaymentDate"></gts-date>
                            </div>`,
                }, {
                    name: "SupplierName",
                    displayName: "Beneficiary",
                    field: "Beneficiary",
                    width: 250,
                    enableSorting: true,
                    visible: this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "CustomerName",
                    displayName: "Customer",
                    field: "Customer",
                    width: 150,
                    enableSorting: true,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "CurrencyCode",
                    displayName: "Currency",
                    field: "CurrencyCode",
                    width: 80,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "PaymentAmount",
                    displayName: "Amount",
                    field: "PaymentAmount",
                    width: 100,
                    cellClass: 'text-right',
                    cellFilter: "number: 2",
                    enableFiltering: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "IsAdvancedPayment",
                    displayName: "Advanced Payment",
                    enableFiltering: false,
                    enableSorting: true,
                    width: 100,
                    cellTemplate: `<input type="checkbox"  ng-disabled="true" ng-model="row.entity.IsAdvancedPayment">`
                }, {
                    name: "ValueDate",
                    displayName: "Value Date",
                    field: "ValueDate",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="ValueDate"></gts-date>
                            </div>`,
                }, {
                    name: "PaidDate",
                    displayName: "Date Paid",
                    field: "PaidDate",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="PaidDate"></gts-date>
                            </div>`,
                },
                {
                    name: "ResponsibleUserName",
                    displayName: "Responsible User",
                    field: "ResponsibleUser",
                    width: 150,
                    enableSorting: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                },
                {
                    name: "Status",
                    displayName: "Status",
                    field: "Status",
                    width: 150,
                    enableSorting: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                },
                {
                    name: "IssuingBank",
                    displayName: "Issuing Bank",
                    field: "IssuingBank",
                    width: 150,
                    enableSorting: true,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }
                ]
            };


        }

        angular.module("app").controller("paymentListCtrl", controllers.payment.paymentListCtrl);
    }
}